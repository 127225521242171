
import { Options, Prop, Vue, Watch } from "vue-property-decorator";
import TableView from "@views/page/components/table-view.vue";
import getUTC from "@/function/getUTC";
import PageResponse from "@/dataSource/api/pagination-helper/lib/PageResponse";
import EnumLogLevel from "@/dataSource/api/tirscript/logger-micro-service/enums/EnumLogLevel";
import GetWebLogsResponse from "@/dataSource/api/logger-micro-service/services/models/response/web-getter-service/GetWebLogsResponse";
import WebLogsItemResponse from "@/dataSource/api/logger-micro-service/services/models/response/web-getter-service/WebLogsItemResponse";
import GetWebLogRequest from "@/dataSource/api/logger-micro-service/services/models/request/web-getter-service/GetWebLogRequest";
import FilterModel from "../../components/filter-model";
import HttpMethodEnum from "@/dataSource/api/tirscript/logger-micro-service/enums/HttpMethodEnum";

@Options({
  name: "HttpActiveLogsView",
  components: {
    TableView,
  },
})
export default class HttpActiveLogsView extends Vue {
  @Prop({ default: new FilterModel() }) filter: FilterModel;

  enumMethod = HttpMethodEnum;
  innerFilter: GetWebLogRequest;
  items: WebLogsItemResponse[] = [];
  pageResponse: PageResponse = new PageResponse();

  takePage: number = 20;
  skipPage: number = 0;

  noPagination = false;
  isLoading = false;

  created() {
    if (this.filter && (this.filter.SkipPage || this.filter.SkipPage == 0)) {
      this.skipPage = this.filter.SkipPage;
    }
  }

  type(item: any) {
    switch (item.Level) {
      case EnumLogLevel.Error:
        return "error";
      case EnumLogLevel.Warn:
        return "warn";
      default:
        return "default";
    }
  }

  onFilterChange() {
    this.skipPage = 0;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).reset();
    }
    this.refresh();
  }

  mounted() {
    this.refresh();
  }

  // методы логов
  async refresh() {
    if (!this.filter) {
      return;
    }

    let response: GetWebLogsResponse = null;
    this.isLoading = true;
    this.innerFilter = {
      OnlyActiveRequests: null,
      OnlyOpenedConnections: null,
      ProjectLogGroupId: this.filter.ProjectLogGroupId,
      RequestId: this.filter.RequestId,
      Ip: this.filter.Ip,
      SessionToken: this.filter.SessionToken,
      Url: this.filter.Url,
      UserId: this.filter.UserId,

      LogLevel: this.filter.LogLevel,
      FromDate: this.filter.DateFrom,
      ToDate: this.filter.DateTo,
      Page: { Skip: this.skipPage, Take: this.takePage },
    };
    let tmpFilter = getUTC(this.innerFilter);
    try {
      response = await this.$api.WebLogGetterService.getActiveLogsAsync(tmpFilter);
    } catch (error) {
      this.$notification.error(error.ErrorMessage);
      this.isLoading = false;
    }
    this.isLoading = false;
    this.pageResponse = response.Page;
    this.items = response.Items;
    if (this.$refs.pagination) {
      (this.$refs.pagination as any).currentPage = this.skipPage / this.takePage + 1;
    }
  }

  // событие срабатывет при изменении пагинации при просмотре
  onPaginationChanged(page: number) {
    let skip = (page - 1) * this.takePage; // текущая страница * кол-во записей на странице
    if (this.skipPage === skip) {
      return;
    }

    this.skipPage = skip;
    this.updateQuery();

    this.refresh();
  }

  getIntervalTime(dateStart: Date, dateEnd: Date): number {
    return new Date(dateEnd).getTime() - new Date(dateStart).getTime();
  }

  updateQuery() {
    this.$emit("updateQuery", this.skipPage);
  }
  /**
   * Разница дат в ms
   * @param date1
   * @param date2
   */
  getms(date1: string, date2: string) {
    try {
      let res = Math.abs((new Date(date2) as any) - (new Date(date1) as any));

      return res || res == 0 ? `(${res})  ms` : "-";
    } catch {
      return "-";
    }
  }
}
